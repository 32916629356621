
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { reportError } from '~/utility/reportError'

@Component
export default class PuFlag extends Vue {
  @Prop()
    countryIso: string

  @Prop({ default: true })
    lazy: boolean

  get flagPath() {
    try {
      if (this.countryIso === 'GB') {
        return require('./images/GB.svg?data')
      } else if (this.countryIso === 'FR') {
        return require('./images/FR.svg?data')
      } else if (this.countryIso === 'DE') {
        return require('./images/DE.svg?data')
      } else {
        return require(`./images/${this.countryIso}.svg`)
      }
    } catch (err) {
      reportError(err as Error)
      return ''
    }
  }
}
